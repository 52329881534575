import React from "react";

const WorkCard = (props) => {
  const { title, company, duration, description, keywords } = props;

  const keywordlists = keywords.map((li, id) => <li key={id}> {li} </li>);

  return (
    <React.Fragment>
      <div className="experience margin-b-50">
        <h4>
          <b> {title.toUpperCase()} </b>
        </h4>
        <h5 className="font-yellow">
          <b> {company.toUpperCase()} </b>
        </h5>
        <h6 className="margin-t-10"> {duration.toUpperCase()} </h6>
        <p className="font-semi-white margin-tb-30"> {description} </p>
        <ul className="list margin-b-30">{keywordlists}</ul>
      </div>
    </React.Fragment>
  );
};

export default WorkCard;
