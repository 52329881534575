import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./App.css";

import "styles/responsive.css";
import "styles/styles.css";

import Header from "components/Header";
import AboutMe from "components/AboutMe";
import Work from "components/Work";
import Education from "components/Education";
import Projects from "components/Projects";
import Counter from "components/Counter";
import ContactMe from "components/ContactMe";
import Footer from "components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <AboutMe />
      <Work />
      <Education />
      {false && (
        <Counter
          degrees="2"
          projectCompleted="4"
          numberOfclient="45"
          projectDone="3"
        />
      )}
      <Projects />
      <ContactMe />
      <Footer />
    </div>
  );
}

export default App;
