import React from "react";
import Intro from "./Intro";
import Nav from "./Nav";

const Header = () => {
  return (
    <div>
      <Nav />
      <Intro />
    </div>
  );
};

export default Header;
