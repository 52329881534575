import React, { Component } from "react";

import { getConfig } from "config";

import "./main.scss";

class ContactMe extends Component {
  config = getConfig();

  constructor() {
    super();
    this.state = {
      name: "",
      subject: "",
      email: "",
      message: "",
      sent: false,
    };
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };
  // handle submit form to send messenge
  sendMessage = async (event) => {
    event.preventDefault();
    document.body.style.cursor = "wait";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      }),
    };
    try {
      await fetch(`${this.config.apiOrigin}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.id !== "0") {
            this.setState({
              name: "",
              subject: "",
              email: "",
              message: "",
              sent: true,
            });
            document.body.style.cursor = "initial";
            // timeout to show alert
            setTimeout(() => {
              this.setState({
                ...this.state,
                sent: false,
              });
            }, 5000);
          } else {
            this.setState({
              ...this.state,
              sent: true,
            });
            document.body.style.cursor = "initial";
            // timeout to show alert
            setTimeout(() => {
              this.setState({
                ...this.state,
                sent: false,
              });
            }, 5000);
          }
        });
    } catch (error) {
      this.setState({
        ...this.state,
        sent: true,
      });
      document.body.style.cursor = "initial";
      // timeout to show alert
      setTimeout(() => {
        this.setState({
          ...this.state,
          sent: false,
        });
      }, 10000);
      console.log(error);
    }
  };

  render() {
    return (
      <div>
        <section className="about-section section">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="heading">
                  <h3>
                    <b>Contact Me</b>
                  </h3>
                  <h6 className="font-lite-black">
                    <b>Have a Project?</b>
                  </h6>
                  <br />
                </div>
              </div>
              <div className="col-sm-8">
                <div>
                  <form
                    action="#"
                    className=" p-4 p-md-5 contact-form"
                    onSubmit={this.sendMessage}
                  >
                    {this.state.sent && this.state.message === "" && (
                      <div className="alert alert-success" role="alert">
                        Messege Sent successfully !
                      </div>
                    )}
                    {this.state.sent && this.state.message !== "" && (
                      <div className="alert alert-danger" role="alert">
                        Couldn't sent messege !
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            name="subject"
                            value={this.state.subject}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            id=""
                            cols="30"
                            rows="7"
                            className="form-control"
                            placeholder="Message"
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            required={true}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" align="right">
                          <input
                            id="send_btn"
                            type="submit"
                            value="Send Message"
                            className="btn btn-primary py-3 px-6"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row addresses">
                    <div className="dbox w-100 d-flex ">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="icon ion-ios-location"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Address:</span> Berhampore, West Bengal, IN
                          742134
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="icon ion-ios-telephone"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Phone:</span>{" "}
                          <a href="tel://9876543210">+91 98765 43210 </a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="icon ion-ios-chatboxes"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Email:</span>{" "}
                          <a href="mailto:mrinjamul@gmail.com">
                            mrinjamul@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* <div className="dbox w-100 d-flex">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="icon ion-planet"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Website</span>{" "}
                          <a href="https://injamul.is-a.dev">mrinjamul.is-a.dev</a>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ContactMe;
