import React from "react";
import WorkCard from "./WorkCard";

import "./main.scss";

import workdata from "./workexp.json";

const Work = () => {
  const workExp = workdata.map((work, id) => (
    <WorkCard
      key={id}
      title={work.title}
      company={work.company}
      duration={work.duration}
      description={work.description}
      keywords={work.keywords}
    />
  ));

  return (
    <div>
      <section className="experience-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="heading">
                <h3>
                  <b>Work Experience</b>
                </h3>
                <h6 className="font-lite-black">
                  <b>PREVIOUS JOBS</b>
                </h6>
              </div>
            </div>
            <div className="col-sm-8">{workExp}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Work;
