import React from "react";
import ProjectCard from "./ProjectCard";

import "./main.scss";

import projects from "./projects.json";

const Projects = () => {
  const projectslists = projects.map((project, id) => (
    <ProjectCard
      key={id}
      name={project.name}
      protype={project.protype}
      time={project.time}
      badges={project.badges}
      imgurl={project.imgurl}
      toptext={project.toptext}
      description={project.description}
      url={project.url}
      keywords={project.keywords}
    />
  ));

  return (
    <div>
      <section className="experience-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="heading">
                <h3>
                  <b>Projects</b>
                </h3>
                <h6 className="font-lite-black">
                  <b>MY PROJECTS</b>
                </h6>
              </div>
            </div>
            <div className="col-sm-8">
              {projectslists}
              <div className="learn-more">
                <h5 id="more-project">
                  <b>
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://github.com/mrinjamul"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      More on Github
                    </a>
                  </b>
                </h5>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
