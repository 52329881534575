import React from "react";
import ReactTooltip from "react-tooltip";

import "./intro.scss";

import profile_1 from "images/profile-1-250x250.jpg";

const Intro = () => {
  return (
    <div>
      <section className="intro-section">
        <div className="container">
          <div className="row">
            <div className="col-md-1 col-lg-2"></div>
            <div className="col-md-10 col-lg-8">
              <div className="intro">
                <div className="profile-img">
                  <img src={profile_1} alt="" />
                </div>
                <h2>
                  <b>Injamul Mohammad Mollah</b>
                </h2>
                <h4 className="font-yellow">Software Developer</h4>
                <ul className="information margin-tb-30">
                  <li>
                    <b>EMAIL : </b>mrinjamul@gmail.com
                  </li>
                </ul>
                <ul className="social-icons">
                  <li>
                    <a
                      data-tip
                      data-for="github"
                      href="https://github.com/mrinjamul"
                    >
                      <i className="ion-social-github"></i>
                    </a>
                    <ReactTooltip id="github" place="left">
                      <span>Github</span>
                    </ReactTooltip>
                  </li>
                  <li>
                    <a data-tip data-for="blogs" href="http://mrinjamul.github.io/blog/">
                      <i className="ion-ios-paper"></i>
                    </a>
                    <ReactTooltip id="blogs">
                      <span>Blogs</span>
                    </ReactTooltip>
                  </li>
                  <li>
                    <a
                      data-tip
                      data-for="linkedin"
                      href="https://www.linkedin.com/in/mrinjamul/"
                    >
                      <i className="ion-social-linkedin"></i>
                    </a>
                    <ReactTooltip id="linkedin">
                      <span>LinkedIn</span>
                    </ReactTooltip>
                  </li>
                  <li>
                    <a
                      data-tip
                      data-for="twitter"
                      href="https://twitter.com/mrinjamul"
                    >
                      <i className="ion-social-twitter"></i>
                    </a>
                    <ReactTooltip id="twitter">
                      <span>Twitter</span>
                    </ReactTooltip>
                  </li>
                  {/* <li>
                                        <a data-tip data-for='instagram' href="https://www.instagram.com/mrinjamul/">
                                            <i className="ion-social-instagram"></i>
                                        </a>
                                        <ReactTooltip id='instagram'>
                                            <span>Instagram</span>
                                        </ReactTooltip>
                     </li> */}
                  <li>
                    <a data-tip data-for="resume" href="resume/">
                      <i className="ion-android-list"></i>
                    </a>
                    <ReactTooltip id="resume" place="right">
                      <span>Resume</span>
                    </ReactTooltip>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
