import React from "react";

const ProjectCard = (props) => {
  const {
    name,
    protype,
    time,
    badges,
    imgurl,
    toptext,
    description,
    url,
    keywords,
  } = props;
  const keywordlists = keywords.map((li, id) => <li key={id}> {li} </li>);

  const badgeslist = badges.map((badge, id) => (
    <a href={badge.url} key={id}>
      <img alt={name} src={badge.src} />
    </a>
  ));

  return (
    <React.Fragment>
      <div className="experience margin-b-50">
        <h4>
          <b> {name.toUpperCase()} </b>
        </h4>
        <h5 className="font-yellow">
          <b> {protype.toUpperCase()} </b>
        </h5>
        <h6 className="margin-t-10"> {time.toUpperCase()} </h6>
        <p className="font-semi-white margin-tb-30">
          {imgurl && <img src={imgurl} width="420px" alt={name} />}
          {badgeslist}
          <br />
          {toptext}
          <br />
          {description}
          <br />
          <br />
          <a
            href={url}
            style={{ fontSize: "0.9em", color: "#00FFFF" }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {url}
          </a>
        </p>
        <ul className="list margin-b-30">{keywordlists}</ul>
      </div>
    </React.Fragment>
  );
};

export default ProjectCard;
