import React from "react";

const EduCard = (props) => {
  const { course, institute, passed, time, description } = props;

  const courseTime = passed ? "PASSED IN " + time : "EXPECTED IN " + time;
  return (
    <React.Fragment>
      <div className="education margin-b-50">
        <h4>
          <b> {course.toUpperCase()} </b>
        </h4>
        <h5 className="font-yellow">
          <b> {institute.toUpperCase()} </b>
        </h5>
        <h6 className="font-lite-black margin-t-10">
          {" "}
          {courseTime.toUpperCase()}{" "}
        </h6>
        <p className="margin-tb-30"> {description} </p>
      </div>
    </React.Fragment>
  );
};

export default EduCard;
