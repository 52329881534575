import React from "react";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import "./SkillProgress.scss";

const SkillProgress = (props) => {
  // const { gradientId, gradientTransform } = this.props;
  return (
    <div className="circular-progress">
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient
            id={"progressBarColor"}
            gradientTransform={`rotate(90)`}
          >
            <stop offset="0%" stopColor={props.startColor} />
            <stop offset="100%" stopColor={props.stopColor} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbarWithChildren
        value={props.inProgress === true ? 50 : props.percentage}
        counterClockwise
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <div>
          <b> {props.inProgress === true ? `--` : props.percentage}%</b>
          <br />
          <p> {props.name} </p>
          <p hidden={!props.inProgress}>In progress</p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default SkillProgress;
