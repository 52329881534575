import React from "react";
import EduCard from "./EduCard";

import "./main.scss";

import state from "./edu.json";

const Education = () => {
  const EduCards = state.map((edu, id) => (
    <EduCard
      key={id}
      course={edu.course}
      institute={edu.institute}
      passed={edu.passed}
      time={edu.time}
      description={edu.description}
    />
  ));

  return (
    <div>
      <section className="education-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="heading">
                <h3>
                  <b>Education</b>
                </h3>
                <h6 className="font-lite-black">
                  <b>ACADEMIC CAREER</b>
                </h6>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="education-wrapper">{EduCards}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Education;
