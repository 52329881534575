import React from "react";

import "./main.scss";

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <div>
      <footer>
        <p className="copyright">
          Copyright &copy; 2019-
          {year} All rights reserved <br /> made with &nbsp;
          <a
            className="easter"
            href="https://mrinjamul.github.io/github-pages/"
          >
            <i className="ion-heart" aria-hidden="true"></i>
          </a>
          &nbsp; by Injamul Mohammad Mollah
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://mrinjamul.github.io/github-pages/">
            <i className="icon ion-planet"></i>
          </a> */}
        </p>
        <p></p>
      </footer>
    </div>
  );
};

export default Footer;
