import React from "react";

import "./header.scss";

const Nav = () => {
  return (
    <div>
      <header>
        <div className="container">
          <div className="heading-wrapper">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="info">
                  <i className="icon ion-ios-location-outline"></i>
                  <div className="right-area">
                    <h5>Berhampore</h5>
                    <h5>West Bengal,IN 742134</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="info">
                  <i className="icon ion-ios-telephone-outline"></i>
                  <div className="right-area">
                    <h5>+91 98765 43210 </h5>
                    <h6>MON - FRI , 9AM - 9PM</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="info">
                  <i className="icon ion-ios-chatboxes-outline"></i>
                  <div className="right-area">
                    <h5>mrinjamul@gmail.com</h5>
                    <h6>REPLY IN 24 HOURS</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a
            hidden={true}
            className="downlad-btn"
            href="https://github.com/mrinjamul"
          >
            Download CV
          </a>
        </div>
      </header>
    </div>
  );
};

export default Nav;
