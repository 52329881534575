import React from "react";
// import { useState } from "react";
import SkillProgress from "./SkillProgress";

import "./main.scss";

import state from "./skills.json";

const AboutMe = () => {
  //
  // const [state] = useState([
  //   {
  //     name: "HTML5 & CSS3",
  //     percentage: 80,
  //     inProgress: false,
  //   },
  // ]);

  const Skills = state.map((skill, id) => (
    <SkillProgress
      key={id}
      name={skill.name}
      percentage={skill.percentage}
      inProgress={skill.inProgress}
      startColor={`#5a0075`}
      stopColor={`#30003e`}
    />
  ));

  return (
    <div>
      <section className="about-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="heading">
                <h3>
                  <b>About me</b>
                </h3>
                <h6 className="font-lite-black">
                  <b>PROFESSIONAL PATH</b>
                </h6>
              </div>
            </div>
            <div className="col-sm-8">
              <p className="margin-b-50">
                I am a Computer Science student from Murshidabad College of Engineering and Technology.
                <br />
                <br />
                I love exploring new technologies.
                <br />
                <br />
                In my spare time, I love cotributing to open-source projects on Github.
              </p>

              <div className="row">{Skills}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutMe;
